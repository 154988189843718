<template>
  <div>
    <h1 class="-mt-3">BUDOVA NÁKLADOVÉHO NÁDRAŽÍ ŽIŽKOV</h1>
    <p>Nákladové nádraží Žižkov je nejvýznamnější, největší a&nbsp;nejzachovalejší funkcionalistický průmyslový areál v&nbsp;Praze. Od roku 2013 je tento světový unikát památkově chráněným komplexem (rejst.&nbsp;č.&nbsp;ÚSKP&nbsp;105038).</p>
    <p class="mt-4">Jeho historie se začala psát už v&nbsp;roce 1927, kdy drážní inženýr Miroslav Chlumecký (1878–1957) vypracoval Dispoziční plán železničních úprav v&nbsp;Praze, ve kterém si terminál představoval jako dvojici souběžných skladišť v&nbsp;čele s&nbsp;administrativní budovou. Zatímco severní objekt s&nbsp;jedním suterénem určil k&nbsp;odesílání zboží, jižní – delší a&nbsp;čtyřtraktový se dvěma suterény, měl naopak sloužit k&nbsp;jeho vydávání. Obousměrný provoz ale Chlumecký za pomocí vlastní metody trojrozměrných grafikonů vertikálně oddělil. Provozu státních drah vyhradil obě přízemí vybavená rampami, nájemcům dal k&nbsp;dispozici prostřední peron propojený se skladišti desítkou ocelových věží s&nbsp;výtahy a&nbsp;komunikačními lávkami.</p>
    <p class="mt-4">Chlumeckého vizi, která se stala první a&nbsp;zároveň poslední uskutečněnou etapou jeho Dispozičního plánu, ale konkrétní podobu propůjčili až architekti Karel Caivas (1897–1961) a&nbsp;Vladimír Weiss (1897–1989), podle jejichž projektu bylo nádraží v&nbsp;letech 1931–1935 realizováno. Pravidelný provoz byl&nbsp;zahájen 1.&nbsp;3.&nbsp;1936 a&nbsp;dnešních rozměrů komplex dosáhl v&nbsp;roce 1940, kdy byl rozšířen o&nbsp;dvojici modulů přistavěných k&nbsp;oběma křídlům.</p>
    <address>Jakub Potůček</address>
    <lightgallery class="grid lg:grid-cols-3 gap-8 mt-12 pointer-children">
      <img src="../assets/images/04-budova/MUSA-NNZ-1.jpg" alt="" rel="preload">
      <img src="../assets/images/04-budova/MUSA-NNZ-2.jpg" alt="" rel="preload">
      <img src="../assets/images/04-budova/MUSA-NNZ-3.jpg" alt="" rel="preload">
      <img src="../assets/images/04-budova/MUSA-NNZ-4.jpg" alt="" rel="preload">
      <img src="../assets/images/04-budova/MUSA-NNZ-5.jpg" alt="" rel="preload">
      <img src="../assets/images/04-budova/MUSA-NNZ-6.jpg" alt="" rel="preload">
    </lightgallery>
    <iframe class="w-full h-96 mt-12" src="https://www.ceskatelevize.cz/porady/1095913550-nedej-se/215562248430038/"></iframe>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
window.$ = $;

import Lightgallery from 'lightgallery/vue';

export default {
  name: 'Budova',
  components: {
    Lightgallery,
  },
  mounted() {
    window.scrollTo({ top: 0});
  },
}
</script>
